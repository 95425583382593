.footer {
  border-radius: 5px 5px 0 0;
  background-image: url(../../../public/images/header.jpg);
  @extend %bacgroundPosition;
  overflow-x: hidden;
  padding: 50px 0;

  @include onTablet {
    border-radius: 10px 10px 0 0;
  }

  &__info {
    &--container {
      width: max-content;
      margin: 0 auto;

      @include onTablet {
        margin: 0;
        width: 100%;
        display: flex;
        justify-content: space-between;
      }
    }

    &--line {
      position: relative;
      bottom: 32px;
      width: 100%;
      height: 2px;
      border-radius: 2px;
      background-color: $pink;
    }
  }

  &__menu {
    @include onTablet {
      margin: 0;
      display: grid;
    }
  }

  &__part {
    margin: auto auto 40px;
    padding: 0;

    @include onTablet {
      margin-bottom: 50px;
    }
  }

  &__list {
    @include onTablet {
      order: 2;
      margin-bottom: 0;
    }

    @include onDesktop {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 50px;
    }
  }

  &__item {
    &--last {
      margin-bottom: 0;
      width: max-content;
    }
  }

  &__contacts {
    margin-bottom: 0;
    height: min-content;

    @include onTablet {
      display: flex;
      margin-bottom: 0;
      order: 1;
      height: 100%;
      width: 80%;
      align-items: flex-start;

      .menu__link {
        margin-right: 20px;
      }
    }

    @include onDesktop {
      display: none;
    }
  }

  &__icon {
    fill: $pink;

    &--map {
      margin-right: 20px;
    }
  }

  &__gotop {
    display: flex;
    justify-content: flex-end;

    @include onTablet {
      order: 2;
    }
  }

  &__link {
    text-decoration: none;
    color: $pink;
    text-shadow: 1px 1px 1px rgba(245, 236, 237, 1);

    &--gotop {
      position: fixed;
      height: min-content;
      bottom: 46px;
      padding: 0;
      border: none;
      background-color: transparent;

      @include onTablet {
        right: 39px;
      }

      @include onDesktop {
        right: 8px;
      }

      @media (min-width: 1550px) {
        right: calc((100vw - 1440px) / 2 - (100vw - 1550px) / 4 - 50px);
      }

      opacity: 0.7;

      &-static {
        height: min-content;
        position: static;

        @include onTablet {
          display: none;
        }
      }

      &-svg {
        margin: 0;
        fill: $red;
        transform: rotate(180deg);
        border-radius: 50%;
        z-index: 2;
      }
    }
  }
}
