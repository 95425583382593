.feedback {
  background-color: $pinkred;

  &__flex {
    flex-wrap: wrap;
    justify-content: space-between;

    @include onTablet {
      display: flex;
    }
  }

  &__card {
    display: none;
    width: 49%;
    height: min-content;

    @include onTablet {
      display: block;
      width: 24%;
      margin: 0;
    }

    @include onTablet {
      &:hover {
        box-shadow: 0 0 8px rgba(211, 75, 98, 0.7);
        transform: scale(1.02);
      }
    }
  }

  &__subtitle {
    margin: 0 auto 50px;
  }

  &__slider {
    @include onTablet {
      width: 24%;
      margin: 0;
    }

  }
}
