@mixin hover($_property, $_toValue) {
  transition: #{$_property} 0.3s;
  &:hover {
    #{$_property}: $_toValue;
  }
}

@mixin onTablet {
  @media (min-width: 640px) {
    @content;
  }
}

@mixin onDesktop {
  @media (min-width: 1280px) {
    @content;
  }
}
