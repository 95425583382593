.slider,
.sliderBento,
.sliderFeedback {
  img {
    border-radius: 5px;
  }

  &__container {
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    box-shadow: 0 0 8px rgba(211, 75, 98, 0.7),
      inset 0 0 8px rgba(211, 75, 98, 0.7);
  }

  &--cake {
    @include onDesktop {
      width: calc(50% - 70px);
      position: absolute;
      right: 55px;
      max-width: 705px;
    }

    @media (min-width: 1550px) {
      right: calc((100vw - 1440px) / 2 );
    }
  }

  &-line {
    display: flex;
    position: relative;
    left: 0;
    transition: all ease 1s;

    & > img {
      cursor: pointer;
    }
  }

  &__button {
    padding: 20px;
    border: none;
    background: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;

    &--svg {
      height: 30px;
      fill: $pink;

      &-prev {
        transform: rotate(180deg);
      }
    }

    &--prev {
      left: 0;
    }

    &--next {
      right: 0;
    }
  }
}
