$black: #0f0e08;
$white: #f9f7f8;

$grey: grey;
$green: green;

$backgound: #f9f7f8;
$menuBackgound: #f9f7f8;
$red: #d83c63;
$pink:#fce8f0;
$pinkred: #ffcdda;

$duration: 0.3s;
