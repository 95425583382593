.Loader {
  background-color: $pinkred;
  display: flex;
  position: sticky;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  z-index: 5;

  &__content {
    border-radius: 50%;
    width: 2em;
    height: 2em;
    margin: 1em auto;
    border: 0.3em solid $backgound;
    border-left-color: $red;
    animation: load8 1.2s infinite linear;
  }
}

@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
